@charset "utf-8";

span#menu {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

@media(max-width:850px) {
    .page {
        flex-direction: column-reverse;
    }
}

span#menu nav img#icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: 13px;
    margin-top: -38px;
    width: 15px;
}

span#menu ul {
    margin-right: 20px;
    padding: 40px 10px;
    border-right: 1px solid;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

span#menu ul li{
    text-align: right;
    padding: 10px 20px;
}


span#menu ul li{
    color: var(--corTextoFraco);
    cursor: pointer;
}

span#menu ul li.selected{
    position: relative;
    color: var(--cor);
}

span#menu span#arrow {
    position: fixed;
    top: 50px;
    right: 15.5px;
    width: 10px;
    height: 10px;

    content: ' ';

    background-color: var(--cor);
    border-radius: 100%;
}

span#menu ul li:hover{
    text-shadow: 0px 0px 10px var(--cor);
    color: var(--cor);
}