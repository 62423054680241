.writing {
    white-space: nowrap;
    overflow: hidden;
    color: transparent;
}

@keyframes typing {
    from {
        width: 0;
        color: var(--cor);
    }
    to {
        color: var(--cor);
    }
}