@charset "utf-8";

.cardInfo {
    max-width: 300px;
    padding: 20px;
    background-color: #b3b3b31f;
    color: var(--corTextoFraco);
    border-radius: 10px;
    border-top: 15px solid #009143;
    text-align: justify;
    font-size: 11px;
}

.cardInfo header{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cardInfo header h2{
    font-size: 14px;
}

.cardInfo header:not(h2){
    font-size: 10px;
}

.cardInfo header img{
    width: 50px;
    height: 50px;
    border-radius: 10px;
}

.cardInfo header #texts{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.cardInfo header #texts div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5px;
}