@charset "utf-8";

div#toggle {
    position: relative;

    border: 2px solid var(--cor);
    border-radius: 20px;
    padding: 11px 40px 11px 20px;
    width: 40px;

    font-weight: 700;
    transition: 1s;

    cursor: pointer;
}

div#toggle.dark {
    width: 40px;
    padding: 11px 20px 11px 40px;
    border: 2px solid var(--cor);
    color: var(--cor);
    transition: 1s;
}

div#toggle::after {
    content: '';

    position: absolute;
    
    width: 28px;
    height: 28px;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    transition: .5s;

    margin-right: 5px;
    border-radius: 50px;

    background-color: var(--cor);
}

div#toggle.dark::after {
    content: '';

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: .5s;

    right: 61px;
    margin-left: 5px;
    border-radius: 50px;

    background-color: var(--cor);
}