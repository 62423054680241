div.technology {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    font-weight: bolder;
    color: var(--corTexto);
    transition: 1s;
}

div.technology:hover {
    transform: scale(1.2);
    transition: 1s;
}