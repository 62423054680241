@charset "utf-8";

span#settings {
    position: absolute;
    top: 0;
    right: 0;

    margin-top: 20px;
    margin-right: 20px;

    display: flex;
    align-items: flex-start;
    gap: 20px;
}

@media(max-width: 1300px){
    span#settings {
        position: relative;
        flex-direction: column;
        align-items: flex-end;
    }
}