@charset "utf-8";

.chapter {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

.page {
    max-width: 1000px;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}


@media(max-width:1300px) {
    .page {
        flex-direction: column-reverse;
    }
}



#card {
    width: 200px;
    height: 200px;
    padding: 20px;
    border-radius: 10px;
    background-color: var(--corTransparente);
}

section#technologies ul{
    display: flex;
    gap: 20px;
    margin-top: 30px;
    margin-left: 30px;
    flex-wrap: wrap;
}

.resume {
    padding: 10px;
}

.resume p#description {
    width: 500px;
    margin: 20px;
    color: var(--corTextoFraco);
    text-align: justify;
}

@media(max-width: 700px) {
    .page {
        padding-top: 200px;
        display: flex;
        height: 100vh;
        align-items: center;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }
    .resume h1, .resume p#description, .resume ul {
        width: 70vw;
    }
}