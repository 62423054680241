@charset "utf-8";

#contacts {
    display: grid;
    gap: 20px;
    grid-template-columns: auto auto;
}

@media(max-width: 1300px){
    #contacts {
        display: flex;
        gap: 20px;
        grid-template-columns: auto auto;
        flex-direction: column;
    }
}

.contact {
    width: 300px;
    border-radius: 10px;

    padding: 10px 20px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;

    font-size: 12px;
}

.contact span.line {
    height: 100px;
    width: 1px;
}

.contact img {
    width: 80px;
    height: 80px;
}

.contact div{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media(max-width: 700px) {
    .contact {
        width: 70vw;
        overflow-x: hidden;
    }
}