@charset "utf-8";

#drawer {
    width: 300px;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    
    background-color: black;
    z-index: 100;
    box-shadow: -4px 4px 7px 0px #0000007d;
}

#buttonDrawer {
    position: fixed;
    top: 20px;
    right: 20px;
}

#buttonDrawer img{
    width: 50px;
}