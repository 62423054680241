@charset "utf-8";

span#dropdown {
    display: block;
    width: 180px;
    border: 2px solid;
    border-radius: 20px;
    z-index: 5;
}

span#dropdown .flaglang {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 0px;
    font-weight: 700;
    padding: 6px 20px;
}

span#dropdown .flaglang img.arrow{
    filter: invert(var(--inversor));
}

span#dropdown div#selected {
    cursor: pointer;
}

span#dropdown ul {
    border-top: 1px solid var(--cor);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 10px 0px;
}

span#dropdown ul li{
    cursor: pointer;
}

span#dropdown ul li:hover{
    background-color: rgba(187, 187, 187, 0.277);
    color: var(--cor);
}

span#dropdown ul li:nth-child(0) {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}