@charset "utf-8";

header#cabecalho  {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 25px;
    margin-left: 25px;
}

span#perfil {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

@media(max-width: 700px){
    header#cabecalho  span :not(img){
        font-size: 10px;
    }
    
    span#perfil {
        flex-direction: column;
    }

    header#cabecalho span#perfil {
        flex-direction: row;
    }
    
    header#cabecalho span#perfil img{
        width: 10px;
    }
}