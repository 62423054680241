@charset "utf-8";

#progress {
    position: fixed;
    bottom: 0;
    left: 50%;
    width: 80vw;
    transform: translateX(-50%);
    margin-bottom: 50px;
    border-radius: 50px;
    background-color: var(--corTransparente);
}

@media(max-width: 700px) {
    #progress {
        width: 60%;
    }
}

@media(min-width:1000px) {
    #progress {
        width: 800px;
    }
}

#progress #textHelper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    margin-top: -25px;
    opacity: .7;
    transform: translateX(calc(50% - 25px));
    font-weight: bolder;
}

#progress .botaoDirecional {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background-color: #ff7200;
    
    font-size: 20px;
    font-weight: bolder;
    filter: invert(var(--inversor));
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 2px;
}

#progress .left {
    left: 0;
    margin-left: -35px;
}

#progress .right {
    right: 0;
    margin-right: -35px;
}

#progress .botaoDirecional:hover {
    opacity: 0.5;
}

#line {
    height: 5px;
    border-radius: 50px;
    background-color: var(--cor);

    transition: 2s;
}

#line::after {
    position: absolute;   
}