@charset "utf-8";

:root {
    --corFundo: #061828;
    --corTransparente: #b0b0b02e;
    --corTextoFraco: #818181;
    --cor: white;
    --inversor: 0;
}

* {
    margin: 0;
    padding: 0;

    color: var(--cor);
    font-family: monospace;
}

body {
    width: 100%;
    height: 100vh;
    background: url('./../app/assets/images/background.png') no-repeat center center;
    background-size: cover;
    transition: transform 0.1s ease-out;
    
    background-color: var(--corFundo);
}

ul {
    list-style: none;
}

.blank {
    width: 16px;
    height: 16px;
}

h1.t1 {
    color: var(--cor);
    font-weight: bolder;
}

.link {
    color: var(--cor);
    font-weight: bolder;
}