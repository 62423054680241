@charset "utf-8";

.slide {
    perspective: 1000px; 
}

.slide ul {
    display: block;
}

.slide ul#images li {
    position: fixed;
    box-shadow: 8px 8px 10px #2b2b2b6c;
    border-radius: 10px;
}

.slide ul#images li {
    transition: 1s;
}

.slide ul#images li img{
    border-radius: 10px;
}

.slide ul#buttons {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-bottom: -50px;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;
    padding: 10px 20px;
    z-index: 51;
}

.slide ul#buttons li{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background-color: #575757d2;
    cursor: pointer;
}

.slide ul#buttons li.select{
    background-color: var(--cor);
}